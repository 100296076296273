import React from "react";
import ReactDOM from "react-dom";

import { BrowserRouter } from "react-router-dom";

import App from "./App";

import "./Styles/spinner.css";

import "./Styles/index.css";
import "./Styles/header.css";
import "./Styles/footer.css";
import "./Styles/mobilemenu.css";
import "./Styles/popup.css";
import "./Styles/main.css";

import "./Styles/home.css";
import "./Styles/newscarousel.css";
import "./Styles/contactComponent.css";
import "./Styles/contactForm.css";
import "./Styles/praxis.css";
import "./Styles/kiefer.css";
import "./Styles/kieferSub.css";
import "./Styles/team.css";
import "./Styles/leistungen.css";
import "./Styles/kontakt.css";
import "./Styles/map.css";
import "./Styles/datenschutz.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root")
);
