import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';

class Footer extends Component {
    
    routeTo( path )
    {
        this.props.history.push( path );
    }

    render() {
        return (
            <div className="footer-wrapper">
                <div className="content-wrapper">

                    <div className="footer-item footer-link"
                         onClick={() => this.routeTo('/impressum')}>
                        Impressum
                    </div>
                    <div className="footer-item footer-link"
                         onClick={() => this.routeTo('/datenschutz')}>
                        Datenschutz
                    </div>

                    <div className="footer-item">
                        © Zahnärzte Vieth & Vieth
                    </div>

                </div>
            </div>
        );
    }
}

export default withRouter(Footer);