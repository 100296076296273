import React, { Component } from "react";
import ContentfulClient, {
  RichTextComponent,
} from "../../Services/ContentfulClient";
import { withRouter } from "react-router-dom";
import LinkSVG from "../../Assets/link.svg";

import Spinner from "../../Services/Spinner";

function DoctorImageComponent(props) {
  return (
    <div className="kiefer-main-image-wrapper">
      <img
        className="kiefer-main-image"
        src={props.file}
        alt="Melanie Vieth, Kieferorthopädin"
      />
    </div>
  );
}

class Kieferorth extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mainData: null,
      serviceData: null,
      error: false,
      device: this.getCurrentDevice(),
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({
        device: this.getCurrentDevice(),
      })
    );

    ContentfulClient.getEntries({
      content_type: "kiefer",
      limit: 1,
    })
      .then(
        function (entries) {
          this.setState({
            mainData: entries.items[0],
          });
        }.bind(this)
      )
      .catch(() =>
        this.setState({
          error: true,
        })
      );

    ContentfulClient.getEntries({
      content_type: "kieferService",
      order: "fields.orderID",
    })
      .then(
        function (entries) {
          this.setState({
            serviceData: entries.items,
          });
        }.bind(this)
      )
      .catch((e) =>
        this.setState({
          error: true,
        })
      );
  }

  getCurrentDevice() {
    if (window.innerWidth <= 639) return "mobile";
    return "desktop";
  }

  getCutText(contentArr) {
    // Build full Rich Text String
    let content = "";
    contentArr.forEach((c) => {
      if (c.value === undefined) content += c.content[0].value;
      else content += c.value;
    });

    // Cut to preview portion
    let cutOff = 100;
    let shortContent = content.substring(0, cutOff);
    while (shortContent[shortContent.length - 1] !== " ") {
      cutOff--;
      shortContent = content.substring(0, cutOff);
    }
    return shortContent;
  }

  render() {
    if (this.state.error === true) {
      return (
        <div className="content-wrapper">
          A connection error occured. Please reload the page.
        </div>
      );
    } else if (
      this.state.serviceData === null ||
      this.state.mainData === null
    ) {
      return (
        <div className="content-wrapper">
          <Spinner />
        </div>
      );
    } else {
      return (
        <>
          <div className="leistungen-wrapper">
            <div className="leistungen-list-wrapper kiefer-list-wrapper">
              <div className="kiefer-hero-wrapper">
                {this.state.device === "mobile" ? (
                  <DoctorImageComponent
                    file={this.state.mainData.fields.image.fields.file.url}
                  />
                ) : null}
                <div className="kiefer-main-text-wrapper">
                  <div className="kiefer-main-text-header">
                    Kieferorthopädie
                  </div>
                  <div className="kiefer-main-text-body">
                    <RichTextComponent
                      content={this.state.mainData.fields.content}
                    />
                  </div>
                </div>
                {this.state.device === "desktop" ? (
                  <DoctorImageComponent
                    file={this.state.mainData.fields.image.fields.file.url}
                  />
                ) : null}
              </div>

              {this.state.serviceData.map((entry) => {
                return (
                  <div
                    key={entry.fields.titel}
                    className="kiefer-leistungen-element-wrapper"
                    onClick={() =>
                      this.props.history.push(
                        "kieferorthopaedie:" + entry.fields.titel
                      )
                    }
                  >
                    <div
                      style={{
                        backgroundImage:
                          "url(" + entry.fields.image.fields.file.url,
                      }}
                      className="kiefer-leistungen-image-wrapper"
                    />
                    <div className="kiefer-leistungen-description-wrapper">
                      <div className="kiefer-leistungen-header">
                        {entry.fields.titel}
                      </div>
                      <div className="kiefer-leistungen-body">
                        {/* {console.log(entry.fields.content.content[0].content[0].value)} */}
                        {this.getCutText(
                          entry.fields.content.content[0].content
                        )}
                        ...
                      </div>
                      <footer className="kiefer-leistungen-readmore">
                        {"Weitere Infos"}
                        <img
                          className="more-link-svg"
                          src={LinkSVG}
                          alt=""
                        ></img>
                      </footer>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="circle-graphic-kiefer"></div>
        </>
      );
    }
  }
}

export default withRouter(Kieferorth);
