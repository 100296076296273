import React, { Component } from "react";

import Spinner from "../../Services/Spinner";
import ContentfulClient, {
  RichTextComponent,
} from "../../Services/ContentfulClient";

export default class NewsCarousel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newsData: null,
      error: false,
    };
  }

  componentDidMount() {
    ContentfulClient.getEntries({
      content_type: "news",
      order: "-fields.date",
      limit: 5,
    })
      .then((entries) => {
        this.setState({
          newsData: entries.items,
        });
      })
      .catch((e) => {
        this.setState({
          error: true,
        });
        console.log(e.toString());
      });
  }

  render() {
    if (this.state.error === true) {
      return (
        <div className="content-wrapper">
          A connection error occured. Please reload the page.
        </div>
      );
    } else if (this.state.newsData === null) {
      return (
        <div className="content-wrapper">
          <Spinner />
        </div>
      );
    } else {
      return (
        <div className="news-wrapper">
          {this.state.newsData.map((entry) => {
            return (
              <div key={entry.sys.id} className="news-element-wrapper">
                <div
                  className="news-element-image"
                  style={{
                    backgroundImage:
                      "url(" + entry.fields.image.fields?.file?.url + ")",
                  }}
                ></div>
                <div className="news-element-header">{entry.fields.title}</div>
                <div className="news-element-body">
                  <RichTextComponent content={entry.fields.text} />
                </div>
              </div>
            );
          })}
        </div>
      );
    }
  }
}
