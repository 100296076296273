import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import Cancel from "../../Assets/cancel.svg";

const cookieKey = "cookie";

class CookiePopup extends Component {
  constructor(props) {
    super(props);

    this.state = {
      close: parseInt(localStorage.getItem(cookieKey)),
    };
  }

  closePopup() {
    localStorage.setItem(cookieKey, 1);
    this.setState({
      close: 1,
    });
  }

  render() {
    if (this.state.close !== 1) {
      return (
        <div className="popup-wrapper">
          <div className="popup-header">Cookies</div>
          <div className="popup-text-wrapper">
            Im Zuge der Nutzung dieser Website akzeptieren Sie automatisch, dass
            wir Cookies benutzen.
          </div>
          <div className="popup-button" onClick={() => this.closePopup()}>
            <img className="popup-button-image" src={Cancel} alt="Cookie"></img>
          </div>
          <div
            className="popup-link"
            onClick={() => this.props.history.push("datenschutz")}
          >
            Nähere Informationen
          </div>
        </div>
      );
    }
    return null;
  }
}

export default withRouter(CookiePopup);
