import React, { Component } from "react";
import ContentfulClient, { RichTextComponent } from "../../Services/ContentfulClient";
import Spinner from "../../Services/Spinner";
import Arrow from "../../Assets/arrow.svg";

export default class ContactComponent extends Component {
    constructor(state) {
        super(state);

        this.state = {
            addressData: null,
            openingData: null,
            error: false,
        };
    }

    componentDidMount() {
        ContentfulClient.getEntries({
            content_type: "adresse",
            limit: 1,
        })
            .then(
                function (entries) {
                    this.setState({
                        addressData: entries.items[0],
                    });
                }.bind(this)
            )
            .catch((e) =>
                this.setState({
                    error: true,
                })
            );

        ContentfulClient.getEntries({
            content_type: "openingHours",
            limit: 1,
        })
            .then(
                function (entries) {
                    this.setState({
                        openingData: entries.items[0],
                    });
                }.bind(this)
            )
            .catch(() =>
                this.setState({
                    error: true,
                })
            );
    }

    render() {
        if (this.state.error === true) {
            return (
                <div className="content-wrapper">
                    A connection error occured. Please reload the page.
                </div>
            );
        } else if (
            this.state.openingData === null ||
            this.state.addressData === null
        ) {
            return (
                <div className="content-wrapper">
                    <Spinner />
                </div>
            );
        } else {
            return (
                <div className="contact-list-wrapper">
                    <div className="contact-element-wrapper">
                        <p className="contact-header">Adresse</p>
                        {this.state.addressData.fields.name}
                        <br />
                        {this.state.addressData.fields.street}
                        <br />
                        {this.state.addressData.fields.plz}{" "}
                        {this.state.addressData.fields.ort}
                        <br />
                        <br />
                        <a
                            className="navigation-link"
                            target="blank"
                            href="https://www.google.com/maps/search/?api=1&query=Vieth+Vieth+dental+practice%2C+Limastraße%2C+Berlin"
                        >
                            Navigation mit Google Maps
                            <img src={Arrow} className="navigation-arrow" alt="Arr"></img>
                        </a>
                    </div>
                    <div className="contact-element-wrapper-middle">
                        <p className="contact-header">Öffnungszeiten</p>
                        <div className="contact-vacation">
                            <RichTextComponent content={this.state.openingData.fields.vacation} />
                        </div>
                        <table className="contact-table">
                            <tbody>
                                <tr>
                                    <td>Mo</td>
                                    <td>{this.state.openingData.fields.monday}</td>
                                </tr>
                                <tr>
                                    <td>Di</td>
                                    <td>{this.state.openingData.fields.tuesday}</td>
                                </tr>
                                <tr>
                                    <td>Mi</td>
                                    <td>{this.state.openingData.fields.wednesday}</td>
                                </tr>
                                <tr>
                                    <td>Do</td>
                                    <td>{this.state.openingData.fields.thursday}</td>
                                </tr>
                                <tr>
                                    <td>Fr</td>
                                    <td>{this.state.openingData.fields.friday}</td>
                                </tr>
                                <tr>
                                    <td>Sa</td>
                                    <td>{this.state.openingData.fields.saturday}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="contact-element-wrapper">
                        <p className="contact-header">Kontakt</p>
                        Telefon: {this.state.addressData.fields.telefon}
                        <br />
                        Fax: {this.state.addressData.fields.fax}
                        <br />
                        {this.state.addressData.fields.eMail}
                        <br />
                    </div>
                    <br />
                    <div className="contact-notdienst">
                        Notdienste der Berliner Zahnärzte für Feiertage, Wochenende, und Nächte bis 2:00 Uhr finden Sie unter
                        <a target="blank" href="https://www.kzv-berlin.de/patienten/notdienst.html"> Notdienst-Hilfe</a>.
                    </div>
                </div>
            );
        }
    }
}
