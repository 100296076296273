import React, { Component } from "react";
import ContentfulClient, {
  RichTextComponent,
} from "../../Services/ContentfulClient";

import Spinner from "../../Services/Spinner";

export default class KieferorthLeistung extends Component {
  constructor(props) {
    super(props);

    this.state = {
      serviceTitle: props.match.params.leistung.substring(1),
      error: false,
      data: null,
    };
  }

  componentDidMount() {
    ContentfulClient.getEntries({
      content_type: "kieferService",
      "fields.titel": this.state.serviceTitle,
      limit: 1,
    }).then(
      function (entries) {
        this.setState({
          data: entries.items[0],
        });
      }.bind(this)
    );
  }

  render() {
    if (this.state.error) {
      return <span>A connection error occured. Please reload the page.</span>;
    } else if (this.state.data === null) {
      return <Spinner animation="border" />;
    } else {
      return (
        <div className="leistungen-wrapper">
          <div className="kiefer-sub-image-wrapper">
            <div
              className="kiefer-sub-image"
              style={{
                backgroundImage:
                  "url(" + this.state.data.fields.image.fields.file.url + ")",
              }}
            />
          </div>
          <div className=" kiefer-sub-text-wrapper">
            <div className="kiefer-sub-text-surface">
              <span className="kiefer-sub-text-header">
                {this.state.data.fields.titel}
              </span>
              <div className="kiefer-sub-text-body">
                <RichTextComponent content={this.state.data.fields.content} />
              </div>
            </div>
          </div>
          <div className="circle-graphic-kiefer-sub"></div>
        </div>
      );
    }
  }
}
