import React, { Component } from 'react';
import { Icon } from "@iconify/react";
import callIcon from "@iconify/icons-mdi/phone";

export default class MobileMenu extends Component {

    constructor(props) {
        super(props);
        this.state = {
            device: this.getCurrentDevice()
        };
    }

    componentDidMount() {
        window.addEventListener(
            "resize",
            () => this.setState({
                device: this.getCurrentDevice()
            })
        );
    }

    getCurrentDevice() {
        if (window.innerWidth <= 840) {
            return "mobile";
        }
        return "desktop";
    }

    makeCall() {
        window.location.href = "tel:0308025028";
    }

    render() {
        if (this.state.device === "mobile") {
            return (
                <div className="mobilemenu-wrapper" onClick={() => this.makeCall()}>
                    <Icon icon={callIcon} className="call-icon" />
                </div>
            )
        } else {
            return null;
        }
    }
}