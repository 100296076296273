import React, { Component } from "react";

import { withRouter } from "react-router-dom";

import LogoSmallPng from '../../Assets/logo_apfel_Vieth_tinypng.png';
import InstaIcon from "../../Assets/instagram.svg";

function AppointmentButton() {
  return (
    <a href="javascript:toggleDrFlexAppointments()" className="header-menu-btn">
      <div className="header-menu-btn-text">Online Termin</div>
    </a>
  );
}

function AnamneseButton() {
  return (
    <a href="https://infoskophost.de/o/SPHVPISZNNE4N3BL9LBEWNMHUS8N485D/" target="_blank" className="header-menu-btn anamnese-menu-btn">
      <div className="header-menu-btn-text">Anamnese</div>
    </a>
  );
}

function BrandLogo(props) {
  return (
    <div className={"header-brand-wrapper"} onClick={() => props.routeTo("/")}>
      <div className="header-brand-text">Vieth</div>
      <img className="header-brand-logo" src={LogoSmallPng} alt="Brand Logo" />
      <div className="header-brand-text">Vieth</div>
    </div>
  );
}

class Header extends Component {
  constructor(props) {
    super(props);

    let device = this.getCurrentDevice();
    this.routeTo = this.routeTo.bind(this);

    this.state = {
      device: device,
      menuExtended: "header-menu-extended",
      buttonDisplay: "header-menu-button-hidden",
      appointmentDisplay: "",
      appointmentDisplayMobile: "",
      buttonClass: "",
      menuData: [
        { name: "Team", path: "/team", class: "" },
        { name: "Kieferorthopädie", path: "/kieferorthopaedie", class: "" },
        { name: "Leistungen", path: "/leistungen", class: "" },
        { name: "Kontakt", path: "/kontakt", class: "" },
      ],
    };

    for (let item of this.state.menuData) {
      if (item.path === this.props.history.location.pathname)
        item.class = "header-menu-item-active";
    }
  }

  componentDidMount() {
    this.updateMenuOnSize();
    window.addEventListener("resize", () => this.updateMenuOnSize());

    // Update Menu Text Colour after a site change
    this.props.history.listen(location => {
      let data = this.state.menuData;
      for (let item of data) {
        item.class = "";
        let comparePath = location.pathname.split(":")[0];
        if (item.path === comparePath) {
          item.class = "header-menu-item-active";
        }
      }
      this.setState({
        menuData: data,
      });
    });
  }

  getCurrentDevice() {
    if (window.innerWidth <= 900) {
      return "mobile";
    }
    return "desktop";
  }

  toggleMenu() {
    let newButtonClass = "";
    let newMenuClass = "";
    if (this.state.menuExtended === "header-menu-hidden") {
      newMenuClass = "hidden";
      newButtonClass = "";
    } else {
      newMenuClass = "header-menu-hidden";
      newButtonClass = "open";
    }
    this.setState({
      buttonClass: newButtonClass,
      menuExtended: newMenuClass,
    });
  }

  updateMenuOnSize() {
    let newMenuClass = "";

    if (window.innerWidth <= 900) {
      newMenuClass = "hidden";
    } else {
      newMenuClass = "header-menu-extended";
    }

    this.setState({
      device: this.getCurrentDevice(),
      menuExtended: newMenuClass,
      buttonClass: "",
    });
  }

  routeTo(path) {
    this.props.history.push(path);
    this.updateMenuOnSize();
  }

  openInstagram() {
    window.open("https://www.instagram.com/zahnaerzte.vieth/");
  }

  render() {
    return (
      <div className="header-wrapper">
        <div className="content-wrapper">
          <div className="header-inner-wrapper">
            {this.state.device === "mobile" ? (
              <BrandLogo routeTo={this.routeTo} />
            ) : null}

            {this.state.device === "mobile" ? (
              <div
                className={
                  "header-menu-button menuicon " + this.state.buttonClass
                }
                onClick={() => this.toggleMenu()}
              >
                <label className="menuicon-label">
                  <span className="menuicon-wrap-1">
                    <span className="menuicon-top" />
                  </span>

                  <span className="menuicon-wrap-2">
                    <span className="menuicon-bottom" />
                  </span>
                </label>
              </div>
            ) : null}

            {this.state.device === "mobile" ? <AppointmentButton mobile={true} /> : null}

            <div className={"header-menu-wrapper " + this.state.menuExtended}>
              {this.state.device === "desktop" ? (
                <BrandLogo routeTo={this.routeTo} />
              ) : null}

              {this.state.menuData.map((menuItem) => {
                return (
                  <div
                    key={menuItem.name}
                    className={"header-menu-item " + menuItem.class}
                    onClick={() => this.routeTo(menuItem.path)}
                  >
                    {menuItem.name}
                  </div>
                );
              })}

              {this.state.device === "mobile" ? <div className="header-menu-item"><AnamneseButton /></div> : null}

              <img
                src={InstaIcon}
                className="instagram-image"
                onClick={() => this.openInstagram()}
                alt="Instagram"
              />

              {this.state.device === "desktop" ? <AnamneseButton mobile={false} /> : null}
              {this.state.device === "desktop" ? <AppointmentButton /> : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
