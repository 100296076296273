import React from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

const Contentful = require('contentful');

// Content Delivery API
export const ctfSpaceID = 'neutnv6q2n89'

export const ContentfulClient = Contentful.createClient({
    space: ctfSpaceID,
    accessToken: 'Xxob2azm_ocLbE57Y1HVdibIYAJG33ObfT4tJ0K85eg'
});

export default ContentfulClient;


// CHANGE LATER OR REMOVE
let vidStyle = {};

export function renderMedia(file) {
    if (file.contentType === 'video/mp4') {
        return (
            <div className='embed-responsive embed-responsive-16by9' style={vidStyle}>
                <video controls>
                    <source src={file.url} type='video/mp4' />
                    <p>Your browser doesnt support HTML5 video.</p>
                </video>
            </div>
        )
    } else if (file.contentType === 'image/jpeg' || file.contentType === 'image/png' || file.contentType === 'image/webp' || file.contentType === 'image/svg+xml') {
        return (<img className="img-fluid" src={file.url} alt="Content Implementation" />)
    } else {
        return (<p>Unknown content type</p>)
    }
}

export const bodyOptions = {
    renderNode: {
        'embedded-asset-block': (node) => {
            let file = node.data.target.fields.file;
            let jsx = renderMedia(file);
            let markup = renderToStaticMarkup(jsx);
            return markup;
        }
    }
}

export function RichTextComponent({ content }) {
    return (
        <div
            dangerouslySetInnerHTML={{ __html: documentToHtmlString(content, bodyOptions) }}
        />
    );
}