import React, { Component } from "react";

import { Switch, Route } from "react-router-dom";

// Header Linked Pages
import Home from "./Home/Home";
import Kieferorth from "./Kieferorth/Kieferorth";
import KieferorthLeistung from './Kieferorth/KieferorthLeistung';
import Team from "./Team/Team";
import Leistungen from "./Leistungen/Leistungen";
import ContactPage from "./Contact/ContactPage";

// Footer Pages
import Datenschutz from "./Datenschutz/Datenschutz";
import Impressum from './Impressum/Impressum';

class Main extends Component {

  render() {
    return (
      <div className="main-wrapper">
        <Switch>
          {/* Header Linked Pages */}
          <Route exact path="/" component={Home} />
          <Route exact path="/kieferorthopaedie" component={Kieferorth} />
          <Route path="/kieferorthopaedie:leistung" component={KieferorthLeistung} />
          <Route path="/team" component={Team} />
          <Route path="/leistungen" component={Leistungen} />
          <Route path="/kontakt" component={ContactPage} />

          {/* Footer Pages */}
          <Route path="/datenschutz" component={Datenschutz} />
          <Route path="/impressum" component={Impressum} />

        </Switch>
      </div>
    );
  }
}

export default Main;