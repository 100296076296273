import React, { Component } from "react";
import ContentfulClient, {
  RichTextComponent,
} from "../../Services/ContentfulClient";
import Spinner from "../../Services/Spinner";

import NewsCarousel from "./NewsCarousel";
import ContactComponent from "../Contact/ContactComponent";

function TopImageComponent(props) {
  return (
    <div className="home-box1-image-wrapper">
      <div
        className="home-box1-image"
        style={{
          backgroundImage: "url(" + props.file + ")",
        }}
      ></div>
    </div>
  );
}

export default class NewHome extends Component {
  constructor(props) {
    super(props);

    this.state = {
      device: this.getCurrentDevice(),
      pageData: null,
      error: false,
    };
  }

  componentDidMount() {
    window.addEventListener("resize", () =>
      this.setState({ device: this.getCurrentDevice() })
    );

    ContentfulClient.getEntries({
      content_type: "homepage",
      limit: 1,
    })
      .then((entries) => {
        this.setState({
          pageData: entries.items[0],
        });
      })
      .catch((e) => {
        this.setState({ error: true });
        console.log(e.toString());
      });
  }

  getCurrentDevice() {
    if (window.innerWidth <= 640) {
      return "mobile";
    }
    return "desktop";
  }

  render() {
    if (this.state.error === true) {
      return (
        <div className="content-wrapper">
          A connection error occured. Please reload the page.
        </div>
      );
    } else if (this.state.pageData === null) {
      return (
        <div className="content-wrapper">
          <Spinner />
        </div>
      );
    } else {
      let numArray = [1, 2, 3];
      return (
        <>
          <div className="home-box1-postition-wrapper">
            {this.getCurrentDevice() === "mobile" ? (
              <TopImageComponent
                file={this.state.pageData.fields.headImage.fields.file.url}
              />
            ) : null}
            <div className="home-box1-wrapper">
              <div className="home-box1-message-wrapper">
                <div className="home-box1-message-header">
                  {
                    (window.location.host.includes('kiefer')) ?
                      this.state.pageData.fields.alternateTitle :
                      this.state.pageData.fields.welcomeTitle
                  }
                </div>
                <div className="home-box1-message-body">
                  {this.state.pageData.fields.welcomeText}
                </div>
              </div>
              {this.state.device === "desktop" ? (
                <TopImageComponent
                  file={this.state.pageData.fields.headImage.fields.file.url}
                />
              ) : null}
            </div>
            <div className="circle-graphic-home"></div>
            <div className="circle-graphic-home-1"></div>
            <div className="circle-graphic-home-2"></div>
          </div>
          <NewsCarousel />

          <div className="leistungen-wrapper">
            <div className="home-box-2-wrapper">
              {numArray.map((num) => {
                let sideSwitcher = 0;
                if (num % 2 === 0) sideSwitcher = 1;
                return (
                  <div key={num} className="home-box2-row-wrapper">
                    <div
                      className={
                        "home-box2-element home-box2-text-wrapper home-box2-" +
                        sideSwitcher
                      }
                    >
                      <RichTextComponent
                        content={this.state.pageData.fields["textarea" + num]}
                      />
                    </div>
                    <div
                      className={
                        "home-box2-element home-box2-image-wrapper home-box2-" +
                        (sideSwitcher + 1)
                      }
                      style={{
                        backgroundImage:
                          "url(" +
                          this.state.pageData.fields["image" + num].fields.file
                            .url +
                          ")",
                      }}
                    />
                  </div>
                );
              })}
            </div>
          </div>
          <div className="home-contact-position-wrapper">
            <div className="home-contact-background">
              <div className="home-contact-wrapper">
                <ContactComponent />
              </div>
            </div>
            <div className="circle-graphic-home-3"></div>
            <div className="circle-graphic-home-4"></div>
          </div>
        </>
      );
    }
  }
}
