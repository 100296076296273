import React from "react";
import ContactComponent from "./ContactComponent";
import ContactForm from "./ContactForm";

export default function ContactPage() {
  return (
    <>
      <div className="contact-component-wrapper">
        <div className="content-wrapper">
          <ContactComponent />
          <ContactForm />
        </div>
      </div>
      <div className="circle-graphic"></div>
    </>
  );
}
