import React, { Component } from 'react'

import ContentfulClient from '../../Services/ContentfulClient';

import Spinner from '../../Services/Spinner';

export default class Leistungen extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            error: false
        }
    }

    componentDidMount() {
        ContentfulClient.getEntries({
            content_type: 'serviceElement',
            order: 'fields.orderID'
        })
            .then(function (entries) {

                this.setState({
                    data: entries.items
                });

            }.bind(this))
            .catch(e => this.setState({
                error: true
            }))
    }

    render() {
        if (this.state.error === true) {
            return (
                <div className="content-wrapper">
                    A connection error occured. Please reload the page.
                </div>
            );
        }
        else if (this.state.data === null) {
            return (
                <div className="content-wrapper">
                    <Spinner />
                </div>
            );
        }
        else {
            return (
                <div className="leistungen-wrapper">
                    <div className="leistungen-header-wrapper">
                        Unsere Leistungen
                        </div>
                    <div className="leistungen-subTitle-wrapper">
                        Von Prophylaxe bis Zahnersatz decken wir das gesamte Spektrum der zahnmedizinischen Behandlung ab.
                        </div>
                    <div className="leistungen-list-wrapper">
                        {
                            this.state.data.map(entry => {
                                return (
                                    <div className="leistungen-element-wrapper" key={entry.fields.name}>
                                        <div className="leistungen-element-inner-wrapper">
                                            <div className="leistungen-image-wrapper">
                                                {entry.fields.svg ? (
                                                    <img src={entry.fields.svg.fields.file.url} className="leistungen-image" alt="Leistung Icon" />
                                                ) : null}
                                            </div>
                                            <div className="leistungen-description-wrapper">
                                                <div className="leistungen-element-header">{entry.fields.name}</div>
                                                {entry.fields.subServices ? (<>
                                                    {entry.fields.subServices.map(service => {
                                                        return <div key={service} className="leistungen-element-subservice">{service}</div>;
                                                    })}
                                                </>) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                    <div className="circle-graphic"></div>
                </div>
            )
        }
    }
}
