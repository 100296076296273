import React, { Component } from "react";
import { withRouter } from 'react-router-dom'

import Header from "./HomeComponents/HeaderComponents/Header";
import Footer from "./HomeComponents/FooterComponents/Footer";
import MobileMenu from './HomeComponents/MobileMenu/MobileMenu';

import Main from "./PageComponents/Main";
import CookiePopup from "./HomeComponents/CookiePopup/CookiePopup";

class App extends Component {

  constructor(probs) {
    super(probs);

    this.mainRef = React.createRef();
  }

  componentDidMount() {
    // Auto Scroll To Top when new Page is selected
    this.props.history.listen(() => {
      this.mainRef.current.scrollTo(0, 0);
    });
  }

  render() {
    return (
      <div className="App">
        <Header />
        <div className="body-wrapper" ref={this.mainRef}>
          <Main />
          <Footer />
        </div>
        <MobileMenu />
        <CookiePopup />
      </div>
    );
  }
}

export default withRouter(App);
