import React, { useRef, useEffect } from 'react'

export default function ContactForm() {

    const formRef = useRef(null);

    useEffect(() => {
        formRef.current.addEventListener('submit', (e) => {
            e.preventDefault();

            const formData = {};
            let hasErrors = false;
            for (let child of formRef.current.children) {
                child.disabled = true;
                if (!['INPUT', 'TEXTAREA'].includes(child.tagName) || !child.name)
                    continue;

                formData[child.name] = child.value;
                if (!validate(child))
                    hasErrors = true;
            }

            if (hasErrors) {
                enableForm();
                return;
            }

            submitContact(formData).then((success) => {
                if (success) {
                    // Enable and clear form
                    for (let child of formRef.current.children) {
                        child.disabled = false;
                        if (!['INPUT', 'TEXTAREA'].includes(child.tagName) || !child.name)
                            continue;
                        child.value = '';
                    }

                    alert("Vielen Dank für Ihre Nachricht.");
                    return;
                }

                enableForm();
                alert('Die Nachricht konnte nicht abgeschickt werden. Bitte versuchen Sie es erneut.');
            });
        });
    });

    function enableForm() {
        for (let child of formRef.current.children)
            child.disabled = false;
    }

    function validate(element) {
        element.classList.remove('error');

        if (element.name === 'phone')
            return true;

        if (element.value === '') {
            element.placeholder = element.placeholder + ' (bitte ausfüllen)';
            element.classList.add('error');
            return false;
        }

        return true;
    }

    async function submitContact(formData) {
        const name_encoded = encodeURIComponent(formData.name);
        const message = encodeURIComponent(`
            <h2>Eingehende Nachricht vom Kontaktformular:</h2>
            <b>Name:</b> ${formData.name}<br/>
            <b>E-Mail:</b> ${formData.email}<br/>
            <b>Telefon:</b> ${formData.phone}<br/>
            <b>Nachricht:</b><br/>
            ${formData.message}
        `);
        const url = `https://europe-west2-zahnaerzte-vieth-web.cloudfunctions.net/sendContact?subject=KontaktFormular: ${name_encoded}&body=${message}`;

        const res = await fetch(url);
        return res.ok;
    }

    return (
        <div>
            <form
                className="form-wrapper"
                name="contactForm"
                ref={formRef}
            >
                <h2>Kontaktformular</h2>
                <input
                    className="form-element form-input-name"
                    type="text"
                    name="name"
                    placeholder="Name"
                />
                <input
                    className="form-element"
                    type="email"
                    name="email"
                    placeholder="E-Mail"
                />
                <input
                    className="form-element form-input-phone"
                    type="tel"
                    name="phone"
                    placeholder="Telefon Nummer (optional)"
                />
                <textarea
                    className="form-element form-input-textarea"
                    name="message"
                    placeholder="Nachricht"
                    rows="10"
                ></textarea>
                <input
                    className="form-element form-input-submit"
                    type="submit"
                    value="Abschicken"
                />
            </form>
        </div>
    )
}
