import React, { Component } from "react";

import ContentfulClient, {
  RichTextComponent,
} from "../../Services/ContentfulClient";

import Spinner from "../../Services/Spinner";

export default class Team extends Component {
  constructor(props) {
    super(props);

    this.state = {
      groupData: null,
      teamData: null,
      jobData: null,
      error: false,
    };
  }

  componentDidMount() {

    ContentfulClient.getEntries({
      content_type: "groupImage",
      limit: 1
    })
      .then(
        function (entries) {
          this.setState({
            groupData: entries.items[0]
          });
        }.bind(this)
      )
      .catch(() =>
        this.setState({
          error: true,
        })
      );

    ContentfulClient.getEntries({
      content_type: "member",
      order: "fields.orderID",
    })
      .then(
        function (entries) {
          this.setState({
            teamData: entries.items,
          });
        }.bind(this)
      )
      .catch(() =>
        this.setState({
          error: true,
        })
      );

    ContentfulClient.getEntries({
      content_type: "job_offer",
      limit: 1
    })
      .then(
        function (entries) {
          this.setState({
            jobData: entries.items[0],
          });
        }.bind(this)
      )
      .catch(() =>
        this.setState({
          error: true,
        })
      );
  }

  render() {
    if (this.state.error === true) {
      return (
        <div className="content-wrapper">
          A connection error occured. Please reload the page.
        </div>
      );
    } else if (
      this.state.teamData === null ||
      this.state.jobData === null ||
      this.state.groupData === null
    ) {
      return (
        <div className="content-wrapper">
          <Spinner />
        </div>
      );
    } else {
      return (
        <>
          <div className="team-0-wrapper">
            <div className="content-wrapper">
              <img
                className="team-0-image"
                src={this.state.groupData?.fields?.image?.fields?.file?.url}
                alt="Gruppenfoto"
              />
            </div>
          </div>
          <div className="team-1-wrapper">
            <div className="content-wrapper">
              <div className="team-1-image-wrapper">
                {this.state.teamData.map((member) => {
                  return (
                    <div
                      key={member.fields.name}
                      className="team-1-member-wrapper"
                    >
                      <div
                        className="team-1-member-portrait"
                        style={ (member.fields.image.fields) ? 
                          {
                          backgroundImage:
                            "url(" + member.fields.image.fields.file?.url + ")",
                        } : {
                          backgroundColor: "rgb(216, 242, 172, 0.85)"
                        }}
                      />
                      <div className="team-1-member-text">
                        <p className="team-1-member-name">
                          {member.fields.name}
                        </p>
                        <p className="team-1-member-title">
                          {member.fields.title
                            ? member.fields.title.map((title) => {
                              return (
                                <span key={title}>
                                  {title}
                                  <br />
                                </span>
                              );
                            })
                            : null}
                        </p>
                        <p className="team-1-member-task">
                          {member.fields.task
                            ? member.fields.tasks.map((task) => {
                              return (
                                <span key={task}>
                                  {task}
                                  <br />
                                </span>
                              );
                            })
                            : null}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="circle-graphic"></div>
          </div>
          {this.state.jobData.fields.display ? (
            <div
              className="team-2-wrapper"
              style={{ backgroundImage: "url(" + this.state.jobData.fields.bgImage.fields.file.url + ")" }}
            >
              <div className="content-wrapper">
                <div className="team-2-content-wrapper">
                  <RichTextComponent
                    content={this.state.jobData.fields.content}
                  />
                </div>
              </div>
            </div>
          ) : null}
        </>
      );
    }
  }
}
