import React, { Component } from "react";
import ContentfulClient, {
    RichTextComponent,
} from "../../Services/ContentfulClient";
import Spinner from "../../Services/Spinner";

export default class Impressum extends Component {
    constructor(props) {
        super(props);

        this.state = {
            data: null,
            error: false,
        };
    }

    componentDidMount() {
        ContentfulClient.getEntries({
            content_type: "websiteField",
            "fields.titel": "Impressum",
        })
            .then((entries) => {
                this.setState({
                    data: entries.items[0],
                });
            })
            .catch((e) => {
                this.setState({ error: true });
                console.log(e.toString());
            });
    }

    render() {
        if (this.state.error === true) {
            return (
                <div className="content-wrapper">
                    A connection error occured. Please reload the page.
                </div>
            );
        } else if (this.state.data === null) {
            return (
                <div className="content-wrapper">
                    <Spinner />
                </div>
            );
        } else {
            return (
                <div className="datenschutz-wrapper">
                    <RichTextComponent content={this.state.data.fields.content} />
                </div>
            );
        }
    }
}
